.nav_helper {
  position: fixed;
  width: 79px;
  top: 87px;
  left: 0;
  height: calc(100vh - 87px);
  background: #08132c9c;
  z-index: 2;
  @media only screen and (max-width: 1100px) {
    background: unset;
    top: 60px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: initial;
    display: flex;
    bottom: 0;
    top: unset;
    padding: 8px 0;
    justify-content: center;
    background: #08132c9c;
    backdrop-filter: blur(4px);
  }

  ul {
    @media only screen and (max-width: 768px) {
      display: flex;
    }

    li {
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: whitesmoke;
      border-bottom: 2px solid #a7a7a7;
      border-right: 2px solid #a7a7a7;
      border-left: 2px solid #a7a7a7;
      position: relative;
      @media only screen and (max-width: 1100px) {
        &:nth-child(1){
          border-radius: 0 20px 0 0 ;
        }

      }
      @media only screen and (max-width: 768px) {
        padding: 10px;
        &:nth-child(1){
          border-radius:unset ;
        }
      }
      @media only screen and (max-width: 380px) {
        height: 60px;
      }

      .icon_nav_helper {
        font-size: 48px;
        color: #94a7a9;
        @media only screen and (max-width: 380px) {
          font-size: 35px;
        }
        path {
          stroke: #94a7a9;

        }
      }


      .tooltip {
        width: 500px;
        max-width: 350px;
        position: absolute;
        left: 118px;
        top: 13px;
        background: rgba(65, 65, 65, 0.93);
        padding: 10px 16px;
        box-shadow: 2px 2px 6px -1px #858585;
        border-radius: 20px;
        color: white;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        z-index: 10000;

        @media only screen and (max-width: 768px) {
          top: unset;
          left: 50%;
          bottom: 52vh;
          transform: translateX(-50%);
          max-width: 290px;
          position: fixed;

        }
        @media only screen and (max-height: 400px) {
          bottom: 44vh;
        }


        .container_tooltip_text {
          display: flex;
          align-items: center;
          justify-content: center;


          span{
            display: block;
            margin: 5px;
            padding: 10px 5px;
            border-radius: 6px;
            background: #d64343;
            box-shadow: 0 2px 3px 2px #a09c9c;
          }
          i {
            height: 100%;
            background: #ffffff;
            position: absolute;
            right: 0;
            display: flex;
            font-size: 51px;
            padding: 4px;

            svg {
              width: 100%;
              height: 100%;
            }

            .warning_icon_svg {
              path {
                stroke-dashoffset: 0;
                stroke-width: 1.2px;
                fill: #ffac57;
                animation: warning 1s;
                color: #747474;
                transform-origin: center;
              }
            }

            .check_icon_svg {
              border: 4px solid #eef3f7;
              animation: svg 0.8s;

              path {
                stroke-dasharray: 690;
                stroke-width: 46.2px;
                animation: check 1s;
                color: #00d493;
                transform-origin: center;
              }
            }
          }
        }

        .close_tooltip {
          position: absolute;
          top: -15px;
          right: -14px;
          font-size: 37px;
          color: transparent;


          svg {
            border-radius: 100%;
            border: 2px solid #2ed4e5;
            padding: 5px;
            background: white;
            box-shadow: 1px 1px 5px -1px #5f6b87;


          }

          &:active svg {
            box-shadow: 1px 1px 5px -1px #c82e73;
            transform: scale(0.9);
          }

          path {
            stroke-width: 2.2px;
            stroke: #2ed4e5;
            stroke-dashoffset: 92;
            transition: 1s;
            animation: close_svg_icon 0.5s;
          }
        }
      }
    }

    .button_nav_helper {
      position: relative;

      &:before {
        content: '';
        transform: translate(-30px, -50%);
        transition: 0.3s;
        opacity: 0;
        z-index: -1;
        @media only screen and (max-width: 768px) {
          display: none;
        }

      }

      &:hover:before {
        content: attr(date-text-tooltip);
        width: 100%;
        min-width: 145px;
        position: absolute;
        top: 50%;
        right: -176px;
        background: #686f7ecc;
        color: white;
        padding: 5px;
        border-radius: 10px;
        transform: translate(0, -50%);
        text-align: center;
        opacity: 1;
        box-shadow: 3px 4px 4px 0 #adadad;
        @media only screen and (max-width: 768px) {
          top: -60px;
          left: -79px;
          max-width: 291px;
          display: none;
        }

      }

    }

    .action_button_nav {
      background: #ff8100;
      box-shadow: 5px -2px 7px -3px #606060;
    }

    .action_icon_nav {
      color: white !important;

    }

    .hover_wrap_icon_nav_helper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        transform: translate(-30px, -50%);
        transition: 0.3s;
        opacity: 0;
        z-index: -1;

      }

      &:hover:before {
        content: attr(date-text-tooltip);
        width: 100%;
        min-width: 145px;
        position: absolute;
        top: 50%;
        right: -176px;
        background: #f0f8ff;
        padding: 5px;
        transform: translate(0, -50%);
        color: #545454;
        text-align: center;
        opacity: 1;
        box-shadow: 3px 4px 4px 0 #adadad;
      }

    }


    .color_action {
      position: relative;

      &:before {
        content: '';
        color: #f16a28 !important;
        border: 2px solid #f16a28;
      }
    }

    .active_button {
      background: #fd9d3b;;
      box-shadow: 1px 2px 6px -2px #2c2e4c;

      i {
        color: white;
      }
    }

    .wrap_search {
      display: flex;
      align-items: center;
      position: absolute;
      left: 100px;
      padding: 5px;
      background: #f0f8ffd4;
      box-shadow: 1px 2px 10px -2px #413e3e;
      border-radius: 15px;
      animation: search 0.5s;

      input {
        min-width: 300px;
        font-size: 18px;
        padding: 5px;
        background: #f0f8ff1f;

      }

      i {
        svg {
          font-size: 43px;
          color: #94a7a9;
        }


      }


    }

    .button_nav_helper:active .icon_nav_helper {
      transform: scale(0.9);

    }
  }
}

.report_window{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 53px;
  transform: translate(-50%,0);
  overflow: auto;
  padding: 25px 10px 140px 10px;
  backdrop-filter: blur(6px);
  background: #f0f8ff8a;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  li{
    h3{
      font-size: 25px;
      margin: 16px 0;
      text-align: center;
    }
  }
  .timetable_item{
    width: 50%;
    border: solid 2px #9e9e9e;
    border-radius: 20px;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 550px) {
      width: 95%;
    }
    .wrap_time{
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 22px;
      font-weight: 800;
    }
    .component_icon_list{
      .component_icon_item {
        font-size: 20px;
        margin: 15px 0;
      }
      i{
        background: chocolate;
        color: #f7f7f7;
        padding: 5px;
        margin: 5px;
        border-radius: 5px;
      }

    }
  }
}