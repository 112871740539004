.nav_list {
  position: absolute;
  top: 0;
  left: 60%;
  height: 100vh;
  background: #547fc587;
  padding: 30px 60px;
  z-index: 1;
  animation: header 0.3s;
  @media only screen and (max-width: 1100px) {
    display: none!important;
  }
  @media only screen and (min-width: 1100px) {
    &:before {
      content: '';
      width: 100%;
      height: 10%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      background: linear-gradient(#a1c5ff03, #ffffff);
    }
  }


  li {
    text-align: start;
    a {
      color: #e9e7e7;
      padding: 5px 0;
      margin: 2px 0;
      display: inline-block;
      font-size: 25px;
      white-space: nowrap;
      @media only screen and (max-width: 1300px) {
        font-size: 20px;
        padding: 9px 0;
      }
      @media only screen and (max-width: 1100px) {
        font-size: 24px;
      }
    }
  }
}

.active_button {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 39%;
    background: #c5b05bab;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    padding: 0 10px;
    animation: line 0.3s;
    transform-origin: left;
  }
}

@keyframes header {
  0% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes line {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.nav_list_app {
  width: 100%;
  display: flex;
  justify-content: right;
  position: absolute;
  top: 0;
  left: 0;
  height: unset;
  background: #547fc587;
  backdrop-filter: blur(3px);
  padding: 20px 10px;
  animation: nav_list 0.3s;
  z-index: 10;

  li {
    margin: 0 10px;
  }
}

.button_nav_mobil{
  width: 40px;
  height: 30px;
  margin:  14px 14px  0 0;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  @media only screen and (max-width: 1100px) {
    display: block;
  }
  span{
    width: 38px;
    height: 4px;
    background-color: #e8a62ee0;
    display: block;
    transition: 0.1s;
    &:nth-child(2) {
      margin: 7px 0;
    }
  }
}

.close_bottom{
  transform: rotate(45deg);
  position: absolute;
  top: 12px;

}
.close_center{
  display: none!important;
}
.close_top{
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  top: 12px;
}

.action_nav_menu{
  width: 100%;
  height: 100vh;
  max-height: 2000px;
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
  background: rgba(84, 127, 197, 0.9215686275);
  transition: 0.2s;
  left: 0;
  top: 0;
  padding: 65px 10px 20px 10px;
  animation: action_nav_menu 0.2s;
  transform-origin: top;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
}
@keyframes action_nav_menu {
  0%{
    transform: scaleY(0.8);
  }
  100%{
    transform: scaleX(1);
  }
}
@keyframes nav_list {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}