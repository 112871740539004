.App {
  position: relative;
  overflow: hidden;
}

.stop_scroll{
  height: 100vh;
}


