.modal_window_form{
  width: 350px;
  padding: 10px;
  position: absolute;
  top: 114px;
  left: 118px;
  background: #5d5d5dd6;
  border-radius: 29px;
  animation: action_window 0.3s;
  @media only screen and (max-width: 1100px) {
    top: 59px;
  }
  @media only screen and (max-width: 768px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 5;
    margin: 50px 0;
  }
  @media only screen and (max-width: 550px) {
    width: 90%;

  }
  p{
    font-size: 25px;
    text-align: center;
    color: white;
    padding: 9px 20px;
    font-weight: 700;
    @media only screen and (max-width: 550px) {
      font-size: 23px;
    }
  }
  form{

    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      padding: 10px;
      input{
        width: 100%;
        padding: 10px 24px;
        background: transparent;
        border: 2px solid white;
        border-radius: 29px;
        color: white;
        font-size: 20px;
        box-shadow: 0 2px 3px 2px #a09c9c;
        &::placeholder{
          color: white;
          opacity: 0.8;
        }
      }
    }
  }
  button{
    width: 94%;
    padding: 10px;
    margin: 10px;
    font-size: 18px;
    color: white;
    border-radius: 29px;
    background: #be6767;
    animation: 0.3s;
    box-shadow: 0 2px 3px 2px #a09c9c;
    &:active{
      box-shadow:  0 2px 7px 2px #a09c9c;
    }
  }
  i{
    font-size: 44px;
    position: absolute;
    right: -3px;
    top: -19px;
    color: #707070;

  }
  svg{
    background: #fcfcfc;
    border-radius: 100%;
  }
}

.unset_index{
  z-index: 0;
}
@keyframes action_window {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}