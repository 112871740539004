.children {
  margin: 79px 0;
  display: flex;
  justify-content: center;
  padding: 0 40px;
  @media only screen and (max-width: 850px) {
    margin:unset;
  }
  .float_text {
    position: relative;
    left: 107px;
    font-size: 100px;
    font-weight: 700;
    color: #cfcfcf5e;
    z-index: 1;
    @media only screen and (max-width: 850px) {
      position: absolute;
      left: 4px;
    }

    li {
      margin: 5px;
    }
  }

  .main_ifo_children_block {
    max-width: 1400px;

    .children_photo {
      display: flex;
      justify-content: center;
      position: relative;
      @media only screen and (max-width: 1100px) {
        flex-direction: column-reverse;
        align-items: center;
      }

      &:before {
        content: '';
        width: 63%;
        height: 85%;
        background: #e8a62ee0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      img {
        width: 100%;
        max-width: 470px;
        height: 100%;
        object-fit: contain;
        box-shadow: 0 0 20px -2px #b3b3b3;
        filter: grayscale(1);

        &:nth-child(1) {
          margin: 119px 20px 20px;
        }
      }

      h3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 67px;
        max-width: 590px;
        color: #ffffff;
        background: #e8a62ee0;
        padding: 20px;
        text-align: center;
        @media only screen and (max-width: 520px) {
          font-size: 51px;
        }

      }
    }

    .main_info {
      margin: 130px 0;
      display: flex;
      @media only screen and (max-width: 850px) {
        flex-direction: column;
      }

      .wrap_info {
        h2 {
          color: #7c7c7c;
          max-width: 450px;
        }

        p {
          background: white;
          padding: 43px;
          margin-right: -85px;
          z-index: 22222222;
          font-size: 18px;
          @media only screen and (max-width: 850px) {
            margin-right: unset;
            padding: 35px 0;
          }
        }
      }


      img {
        width: 100%;
        max-width: 470px;
        height: 100%;
        object-fit: contain;
        box-shadow: 0 0 20px -2px #b3b3b3;
        margin-top: -194px;
        filter: grayscale(1);
        z-index: -1;
        @media only screen and (max-width: 850px) {
          margin-top: unset;
        }

      }
    }
  }
}

.list_events {
  width: 100%;
  max-width: 400px;
  max-height: 90vh;
  padding: 20px 0;
  position: relative;
  left: 20%;
  background: #f1f0f0b3;
  align-self: center;
  box-shadow: 4px 10px 20px -2px #7c7c7c;
  z-index: 2;
  animation: list 0.3s;
  @media only screen and (max-width: 1100px) {
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 450px) {
    width: unset;
    padding: 20px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(43, 43, 43);
    background: linear-gradient(0deg, rgba(109, 108, 108, 0.64) 0%, rgba(24, 168, 191, 0.01724439775910369) 100%, rgba(0, 212, 255, 1) 100%);
  }


  h4 {
    font-size: 18px;
    color: #7c7c7c;
    margin: 0 20px;
    text-align: center;
  }

  ul {
    width: 100%;
    max-width: 400px;
    max-height: 75vh;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

    }

    li {
      display: flex;
      align-items: flex-start;
      line-height: 1;
      margin: 20px 10px;
      @media only screen and (max-width: 450px) {
        display: unset;
      }

      .date_dey {
        font-size: 45px;
        margin: 5px;
        font-weight: 800;
        color: #555556;
      }

      .block_date {
        margin: 5px;

        .date_wi {
          display: block;
          font-weight: 800;
          color: #555556;
        }

        .date_mo {
          display: block;
          font-weight: 800;
          color: #555556;

        }
      }

      .wrap_info_events {
        margin: 5px;
        align-self: flex-end;

        h6 {
          font-size: 18px;
        }

        p {
          font-size: 13px;
        }
      }


    }

    .clock {
      display: flex;
      color: #555556;

      span {
        font-size: 15px;
        font-weight: bold;
        display: block;
      }
    }
  }


}

.reset_flex_header {
  justify-content: left !important;
}

@keyframes list {
  0% {
    transform: scaleX(0.7);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes main_info {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}