.block_diagnostics {
  max-width: 1400px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 60px;
    margin: 90px 50px;
    color: #5b5b5b;

    span {
      font-size: 13px;
      display: block;
      margin: 5px;
    }

    @media only screen and (max-width: 950px) {
      font-size: 45px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 35px;
      margin: 90px 20px;
    }
  }

  .diagnostics_description {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 500px;
    margin: 30px 20px;
    @media only screen and (max-width: 450px) {
      margin: 30px 5px;
      min-height: 420px;
    }

    p {
      font-size: 18px;
      color: #f9f9f9;
      margin: 0 20px 20px 20px;
      padding: 70px 40px;
      background: #e09ed0c9;
      @media only screen and (max-width: 450px) {
        margin: 0;
        padding: 70px 10px;
        font-size: 16px;
      }
    }

    .diagnostics_description_img {
      width: 100%;
      max-width: 500px;
      min-width: 270px;
      height: 500px;
      position: absolute;
      top: -68px;
      right: 0;
      object-fit: cover;
      z-index: -1;
      box-shadow: -20px 18px 0px 0px #f0ccf2;
      @media only screen and (max-width: 550px) {
        height: 100vw;
      }

    }
  }

  .block_method_selection {
    margin: 30px 20px;

    .method_selection_title {
      font-size: 25px;
      color: #5b5b5b;
      margin: 20px 50px;
      @media only screen and (max-width: 550px) {
        margin: 30px 15px;
      }
      @media only screen and (max-width: 350px) {
        font-size: 20px;
        margin: 30px 0;
      }
    }

    .method_selection_li {
      position: relative;


      &:before {
        content: '';
        width: 50px;
        height: 50px;
        background: #e09ed0c9;
        position: absolute;
        left: 39px;
        top: -6px;
        transform: rotate(45deg);

        @media only screen and (max-width: 550px) {
          width: 35px;
          height: 35px;
          left: -9px;
        }

      }

      &:after {
        content: "";
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        left: 54px;
        top: 9px;
        transform: rotate(45deg);
        box-shadow: 0 0 3px 3px #e09ed0c9;
        @media only screen and (max-width: 550px) {
          top: 2px;
          left: -2px;
        }


      }

      .method_selection_p_title {
        font-size: 26px;
        color: #5b5b5b;
        margin: 55px 0 0 115px;
        font-weight: 700;
        @media only screen and (max-width: 550px) {
          font-size: 18px;
          margin: 45px 0 0 95px;

        }
        @media only screen and (max-width: 370px) {
          font-size: 18px;
          margin: 45px 0 0 40px;
        }
      }
    }

  }

  .detailed_information {
    p {
      font-size: 18px;
      color: #5b5b5b;
      margin: 25px 0 0 152px;
      @media only screen and (max-width: 550px) {
        margin: 20px 0 0 52px;
      }
      @media only screen and (max-width: 370px) {
        font-size: 16px;
      }

    }
  }

  .diagnostics_enumeration {
    margin: 30px 20px;

    h3 {
      font-size: 40px;
      color: #5b5b5b;
      margin: 20px 50px;
      @media only screen and (max-width: 550px) {
        font-size: 25px;
        margin: 20px 10px;
      }

    }

    .diagnostics_enumeration_ul {
      margin: 45px 30px 45px 78px;
      @media only screen and (max-width: 550px) {
        margin: 45px 20px 45px 30px;
      }
      @media only screen and (max-width: 550px) {
        margin: 45px 20px 45px 30px;
      }
      .diagnostics_enumeration_li {
        margin: 20px 0;
        position: relative;

        &:before {
          content: '';
          width: 25px;
          height: 25px;
          background: #e09ed0c9;
          position: absolute;
          top: 0;
          left: -40px;

        }
        &:after {
          content: "";
          width: 15px;
          height: 15px;
          background: white;
          position: absolute;
          top: 0;
          left: -40px;
          box-shadow: 0 0 3px 1px #d2d2d2;

        }

        p {
          font-size: 18px;
          @media only screen and (max-width: 450px) {
            font-size: 16px;
          }
        }

      }
    }

    .diagnostics_enumeration_attention {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      background: rgba(224, 158, 208, 0.7882352941);
      margin: 73px 85px;
      padding: 30px 20px;
      color: white;
      text-align: center;
      @media only screen and (max-width: 750px) {
        margin: 43px 15px;
      }
      @media only screen and (max-width: 750px) {
        font-size: 18px;
        margin: 43px 0;
      }
      span{
        margin: 0 3px;
        white-space:nowrap;
      }
    }

    .diagnostics_enumeration_p {
      font-size: 20px;
      margin: 90px 15px 15px 97px;
      position: relative;
      @media only screen and (max-width: 550px) {
        margin: 73px 0 0 45px;
        font-size: 16px;
      }

      &:before {
        content: '';
        width: 50px;
        height: 50px;
        background: #e09ed0c9;
        position: absolute;
        left: -75px;
        top: -14px;
        transform: rotate(45deg);
        @media only screen and (max-width: 550px) {
          left: -90px;
        }


      }

      &:after {
        content: "";
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        left: -60px;
        top: 1px;
        transform: rotate(45deg);
        box-shadow: 0 0 3px 3px #e09ed0c9;
        @media only screen and (max-width: 550px) {
          left: -75px;
        }
      }
    }

    .attention {

      .attention_title {
        font-size: 40px;
        color: #5b5b5b;
        margin: 20px 50px;
        position: relative;
        display: block;
        @media only screen and (max-width: 550px) {
          font-size: 30px;
          margin: 20px 5px;
        }
        @media only screen and (max-width: 350px) {
          font-size: 25px;
          margin: 20px 5px;
        }

        &:before {
          content: '';
          width: 50%;
          height: 19px;
          background: #bbbbbb8c;
          position: absolute;
          top: 25px;
          left: 0;
          z-index: -1;
          @media only screen and (max-width: 550px) {
            top: 19px;
          }

        }
      }

      .attention_ul {
        margin: 45px 30px 8px 78px;
        @media only screen and (max-width: 550px) {
          margin: 45px 0 8px 30px;
        }


        .attention_li {
          margin: 20px 10px;
          position: relative;

          &:before {
            content: '';
            width: 25px;
            height: 25px;
            background: #a5a5a5;
            position: absolute;
            top: 0;
            left: -40px;

          }

          p {
            font-size: 18px;
            @media only screen and (max-width: 370px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}