.section_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: images_page 0.5s;
  //padding: 0 10px;


  .instruction_user {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 20px 20px 20px;

    h1 {
      align-self: flex-start;
      position: relative;
      font-size: 40px;
      color: #7c7c7c;
      display: inline-block;
      background: white;
      margin-left: 32px;
      padding: 17px;
      @media only screen and (max-width: 450px) {
        font-size: 9vw;
      }

      &:before {
        content: '';
        width: 50%;
        height: 65%;
        padding: 25px 0;
        position: absolute;
        left: -24px;
        top: 15px;
        border: 15px solid #547fc587;
        z-index: -1;
        @media only screen and (max-width: 1350px) {
          height: 90%;
        }
      }
    }

    .section_attention {
      max-width: 1000px;
      margin: 30px 0;

      h2 {
        margin: 30px 0;
        color: #505050
      }

      li {
        margin: 8px 0;
        display: flex;
        align-items: flex-start;

        i {
          font-size: 30px;
          margin: 0 10px;
          color: #4d95e1;
        }

        span {
          font-size: 18px;
        }
      }
    }

    h2 {
      margin: 20px;
      font-size: 35px;
      color: #505050;
      text-align: center;
      @media only screen and (max-width: 450px) {
        font-size: 9vw;
      }
    }

    .add_text {
      max-width: 1400px;
      font-size: 20px;
      margin: 130px 0 0 0
    }

    .instruction_list {
      max-width: 1400px;
      margin: 0 20px 20px 20px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @media only screen and (max-width: 1350px) {
        flex-direction: column;
      }
      @media only screen and (max-width: 550px) {
        margin: 20px 0;
      }
      @media only screen and (min-width: 1350px) {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: url("../../images/background_header/line.png") no-repeat center;
          background-size: contain;
          z-index: -1;
        }
      }


      .instruction_item {
        width: 30%;
        border: 15px solid #547fc587;
        margin: 69px 20px;
        padding: 20px;
        position: relative;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media only screen and (max-width: 1350px) {
          width: 100%;
          max-width: 600px;
          margin: unset;
          flex-direction: unset;
          justify-content: flex-start;
          align-items: center;
          padding: unset;
        }
        @media only screen and (max-width: 550px) {
          flex-direction: column;
        }

        &:last-child {
          border: unset;
          border-left: 15px solid #547fc587;
          padding: 9px;
        }

        @media only screen and (max-width: 1350px) {
          &:last-child {
            border: unset;
          }
          border: unset

        }


        .wrap_images_title {
          display: flex;

          span {
            font-size: 55px;
            color: #668ecd94;
            display: block;
            font-weight: 800;
          }

          i {
            font-size: 65px;
            color: #668ecd94;
            display: block;
            @media only screen and (max-width: 1350px) {
              font-size: 116px;
            }

          }
        }

        .send_page {
          padding: 0 30px;
          font-size: 25px;
          font-weight: 700;
          color: #a19f9c;
          border: unset;
          display: block;
          background: #ffffff99;
          border-radius: unset;
          text-align: center;

        }

        .title_instruction_on {
          font-size: 21px;
          color: #7f7f7f;
          @media only screen and (max-width: 1350px) {
            padding: 20px;
            font-weight: bold;

          }
          @media only screen and (max-width: 550px) {
            text-align: center;
          }
        }

      }

    }

    .button_transition_page {
      margin: 50px;

      .send_page {
        padding: 10px 30px;
        font-size: 30px;
        font-weight: 700;
        color: #606060;
        border: 5px solid;
        text-align: center;
      }
    }
  }
}

.screensaver_header {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;


  .images_screensaver {
    width: 100%;
    height: 100vh;
    object-fit: contain;
    object-position: 31%;
    position: absolute;
    z-index: -1;
    @media only screen and (max-width: 1100px) {
      object-fit: cover;

    }
    @media only screen and (max-width: 450px) {
      object-position: top;

    }
  }

  .blur_img {
    filter: blur(3px);
  }

  .individual_setting_images {
    object-position: 25%;
  }
}

@media only screen and (max-width: 1000px) {
  .app_screensaver_header {
    overflow: hidden;
  }

}


.transition_application {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translateY(-50%);
}

.button_transition_page {
  position: absolute;
  left: 25px;
  bottom: 0;
  top: unset;
  margin: 0 10px;
  animation: button 0.3s;
  z-index: 3;
  @media only screen and (max-width: 550px) {
    left: 0;
  }

  .send_page {
    padding: 10px 30px;
    font-size: 30px;
    font-weight: 700;
    color: #879ec3;
    border: 5px solid;
    display: block;
    background: #ffffff99;
    border-radius: 50px;
    text-align: center;
    @media only screen and (max-width: 450px) {
      font-size: 24px;
    }
  }
}

.wrap_timetable {
  max-width: 1600px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  padding: 20px 0 20px 20px;
  @media only screen and (max-width: 1100px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 768px) {
    padding: unset;
    margin-top: 54px;
  }

  .timetable {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    height: 100%;
    max-height: 88vh;
    margin: 0 20px 0 20px;
    animation: images_page 0.2s;
    transition: 0.3s;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @media only screen and (max-width: 1680px) {
      width: 100%;
      align-items: center;
      margin: 0 20px 0 77px;
    }

    @media only screen and (max-width: 768px) {
      max-height: 100vh;
      margin: 0 20px 0 20px;
      padding-bottom: 90px;
    }
    @media only screen and (max-width: 550px) {
      margin: unset;
      display: unset;
      padding: 0 5px 39px 5px;
    }

    @media only screen and (max-width: 380px) {
      padding: 0 2px;
    }

    .timetable_item {
      margin: 0 10px 120px 10px;
      border: 5px solid #929292c7;
      border-radius: 29px;
      display: flex;
      animation: images_page 0.5s;
      @media only screen and (max-width: 1680px) {
        width: 99%;
      }
      @media only screen and (max-width: 550px) {
        text-align: center;
        margin: 10px 0 120px 0;
      }


      .title_item_attention {
        font-size: 40px;
        text-align: center;
        font-weight: 800;
        padding: 15px;
        align-self: center;
        color: #b7d0f7;
        text-shadow: 0 0 7px #141414db;
        @media only screen and (max-width: 550px) {
          font-size: 30px;
        }
      }

      .wrap_time {
        min-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 23px 0 0 23px;
        color: #e6e6e6;
        background: #929292c7;
        padding-top: 20px;

        @media only screen and (max-width: 550px) {
          padding-top: 40px;
        }

        .sticky_block {
          position: sticky;
          top: 27px;
          background: #be3a3a;
          border-radius: 10px 0 0 10px;
          padding: 0 3px;
          margin: 2px 1px 19px 0;
          box-shadow: -4px 0px 5px 0px #363636;
          @media only screen and (max-width: 550px) {
            margin: -3px 0 35px 0;
          }
          @media only screen and (max-width: 385px) {
            margin: -7px 0 31px 0;
            padding: 8px 3px
          }

        }

        span {
          font-size: 35px;
          font-weight: bold;
          display: block;
          margin: 10px;
          @media only screen and (max-width: 550px) {
            font-size: 24px;
            margin: 7px 4px 7px 0;
          }
          @media only screen and (max-width: 385px) {
            font-size: 17px;
          }
        }

        i {
          font-size: 15px;
          font-weight: bold;
          display: block;
          margin: 10px;
          white-space: nowrap;
          @media only screen and (max-width: 768px) {
            padding: 10px;
          }
          @media only screen and (max-width: 550px) {
            display: none;
          }
        }


      }

      .component_icon_list {
        display: flex;
        margin: 5px;
        flex-wrap: wrap;
        @media only screen and (max-width: 1680px) {
          width: 85%;
          flex-direction: column;
          align-items: center;
        }
        @media only screen and (max-width: 550px) {
          width: 100%;
          margin: 10px 0;
          padding: 12px 5px;

        }
        @media only screen and (max-width: 360px) {
          margin: 10px 0;
          padding: 12px 5px;
        }

        .component_icon_item {
          width: 48%;
          min-width: 504px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 15px 5px;
          border-radius: 10px;
          background: #ffffffe6;
          box-shadow: 1px 3px 10px -2px #514f4f;
          transition: 0.3s;
          position: relative;
          overflow: hidden;
          @media only screen and (max-width: 1680px) {
            width: 100%;
            min-width: unset;
          }
          @media only screen and (max-width: 1400px) {
            width: 99%;
          }
          //@media only screen and (max-width: 1100px) {
          //  min-width: 375px;
          //  width: unset;
          //
          //}
          @media only screen and (max-width: 550px) {
            margin: 15px 0;
          }
          @media only screen and (max-width: 450px) {
            min-width: unset;

          }
          @media only screen and (max-width: 385px) {
            margin: 10px 0;

          }

          &:hover {
            box-shadow: 1px 3px 13px 2px #514f4f;
          }

          .icons {
            display: flex;
          }

          i {
            font-size: 60px;
            color: #96a1a7;
          }

          .add_serv_icon {
            color: #e1b46c;

            .plus {
              font-size: 20px;
              color: #e1b46c;
              position: relative;
              top: -50%;
              transform: translateY(100%);
            }

          }

          .wrap_additional_information_timetable {
            width: 100%;
            margin: 10px;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            padding: 0 20px;
            @media only screen and (max-width: 550px) {
              width: unset;
              padding: 0 5px;
            }

            .date_item {
              display: none;
              @media only screen and (max-width: 550px) {
                display: block;
                font-size: 29px;
                align-self: center;
                font-weight: 700;
                color: #9f9f9f;
              }
            }


            .additional_information_timetable {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              text-align: initial;
              margin: 5px 0;

              span {
                display: block;
                margin: 0 16px;
                font-size: 18px;
                font-weight: 400;
                @media only screen and (max-width: 550px) {
                  margin: 0 5px;
                }
              }

              .i {
                background: #e1661d;
                padding: 10px;
                color: white;
                font-weight: 700;
                font-size: 26px;
                border-radius: 10px;
                @media only screen and (max-width: 350px) {
                  font-size: 24px;
                }
              }

              i {
                font-size: 20px;
                white-space: nowrap;
                @media only screen and (max-width: 350px) {
                  font-size: 18px;
                }
              }


              .red {
                height: 30px;
                color: red;
                font-size: 30px;
              }
            }
          }

          button {
            width: 90%;
            padding: 10px;
            margin: 10px;
            font-size: 18px;
            color: white;
            border-radius: 10px;
            background: #be6767;
          }
        }

      }

    }
  }

  .stop_scroll {
    overflow: hidden;
  }

  .title_attention {
    width: 69%;
    text-align: center;
    align-self: center;
    margin: 0 60px;
    font-size: 68px;
    font-weight: 800;
    color: #e6e6e6;
    animation: attention 0.3s;
    text-shadow: 2px 2px 13px #545353;
    @media only screen and (max-width: 550px) {
      font-size: 36px;
    }
  }
}

.background_position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(3px);
  animation: images_page 0.2s;
  z-index: -1;
}

.reset_flex {
  display: block;
}

.wrap_booked {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #d07c13ab;
  display: flex;
  align-items: center;
  justify-content: center;

  .booked {
    font-size: 30px;
    font-weight: 700;
    color: #b51e1e;
    display: block;
    text-shadow: 1px 2px 3px #c8c8c8;
  }
}

.highlight {
  color: #f7f7f7;
  background: #ff8100 !important;
  border-radius: 10px;
}

.react-calendar__tile--active {
  background: #5f6b87 !important;
  border-radius: 10px;
  color: white;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.wrap_modal_form {
  height: 100%;
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 55555;
}

.react-calendar__tile {
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  margin: 3px 0;
  font-size: 18px;
  font-weight: bold;
}

.react-calendar {
  margin: 0 0 17px 0;
  flex: 0 0 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 29px 0 0 29px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;


  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media only screen and (min-width: 1600px) {
    border-radius: 29px
  }
  @media only screen and (max-width: 1000px) {
    width: 50%;
    height: 100%;
    right: 0;
    position: absolute;
    transform: translateX(100%);
    transition: 0.5s;
  }
  @media only screen and (max-width: 768px) {
    width: 99.9%;
    border-radius: unset;
  }


  .react-calendar__navigation {
    position: relative;
    justify-content: center;
    background: #ff8100;
    padding-bottom: 93px;
    @media only screen and (max-width: 550px) {
      padding-bottom: 15px;
    }

    .react-calendar__navigation__label {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 25px;
      color: white;
      padding: 10px 18px;
      width: 90%;
      transform: translate(-50%, -50%);
    }

    .react-calendar__navigation__arrow {
      position: relative;
      bottom: -44px;
      left: calc(50% - 70px);
      transform: translate(-50%, 110px);
      color: #00e1d6;
      font-size: 42px;
      margin: 0 30px;
      @media only screen and (max-width: 550px) {
        bottom: 44px;
      }

    }


  }

  .react-calendar__month-view__days__day--weekend {
    color: #b51e1e;
  }

  .react-calendar__navigation__label {
    background: transparent !important;
  }

  .react-calendar__viewContainer {
    margin: 10px 0 0 0;
    padding-bottom: 220px;

  }

  .react-calendar__month-view {
    margin-top: 80px;
    @media only screen and (max-width: 550px) {
      margin-top: 55px;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    background: #5f6b87;
    color: white;
    font-size: 23px;
  }

  abbr[title] {
    text-decoration: unset;
  }


}

.active_calendar {
  transform: translateX(0);
}

.react-calendar__month-view__days {
  margin-top: 20px;
}

.text_info_main {
  max-width: 1000px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 65px;
    font-weight: bold;
    text-align: center;
    color: #707070;
    @media only screen and (max-width: 1100px) {
      font-size: 5vw;
    }
    @media only screen and (max-width: 600px) {
      font-size: 8vw;
    }
    @media only screen and (max-width: 400px) {
      font-size: 10vw;
    }
  }
}


@keyframes attention {
  0% {
    transform: scaleX(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes button {
  0% {
    transform: scaleX(0.6) translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: scaleX(1) translateY(-50%);
    opacity: 1;
  }
}

@keyframes images_page {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}