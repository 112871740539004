.blur_bag {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;

  .wrap_info_admin {
    width: 95%;
    max-width: 500px;
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: scale(1);
    background: rgba(93, 93, 93, 0.8392156863);
    border-radius: 29px;
    z-index: 99999;
    animation: action_info_admin 0.3s;

    .info_admin {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 25px;
        text-align: center;
        color: white;
        padding: 9px 20px;
        font-weight: 700;
      }
    }

    i {
      font-size: 44px;
      position: absolute;
      right: -3px;
      top: -19px;
      color: #707070;

      svg {
        background: #fcfcfc;
        border-radius: 100%;
      }

    }
  }
}

@keyframes action_info_admin {
  0% {
    opacity: 0;
    transform: scale(0.9) ;
  }
  100% {
    transform: scale(1) ;
    opacity: 1;
  }
}