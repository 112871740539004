.free_time_check {
  width: 350px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  position: absolute;
  left: 118px;
  background: #5d5d5dd6;
  border-radius: 29px;
  animation: action_window 0.3s;
  @media only screen and (max-width: 550px) {
    left: 0;
    width: 100%;
  }
  .wrap_loader {
    min-height: 84px;
    .loader {
      position: absolute;
      top: 47px;
    }
  }
  .check{
    font-size: 80px;
    color: aquamarine;
    svg{
      animation: check 0.3s;
    }

  }
  .info_title {
    font-size: 25px;
    color: white;
    margin: 0 39px;
    text-align: center;
    .report{
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        margin:  5px 0;
      }
      .answer{
        font-size: 36px;
        background: #9c9c9c;
        padding: 10px;
        margin: 10px;
        font-weight: bold;
      }
    }
  }
  .i{
    font-size: 44px;
    position: absolute;
    right: -9px;
    top: -6px;
    color: #707070;
    @media only screen and (max-width: 550px) {
      font-size: 49px;
      position: absolute;
      right: 12px;
      top: 8px;
    }
    svg{
      background: #fcfcfc;
      border-radius: 100%;
    }
  }

}

@keyframes check {
  0%{
    transform: scale(0.8) ;
  }
  100%{
    transform: scale(1);
  }
}