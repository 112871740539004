.coffee_break {
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px 20px;


  .info_coffee_break {
    margin: 30px 0;
    text-align: center;
    font-size: 32px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin: unset;
      font-size: 25px;
    }

    &:before {
      content: '';
      width: 90%;
      height: 50%;
      background: #eaeaea;
      position: absolute;
      bottom: 0;
      left: -19px;
      z-index: -1;
      transform: skewX(335deg);
      box-shadow: -1px 3px 7px -2px #d1daea;
    }

    .top_info_block {

    }

    h2 {
      font-size: 1.5em;
      color: #929292;

    }

    span {
      font-size: 0.5em;
    }


  }

  .main_block_coffee_break {
    display: flex;
    margin: 20px 50px;
    @media only screen and (max-width: 1340px) {
      flex-direction: column;
      align-items: center;
    }
    @media only screen and (max-width: 1340px) {
      margin: 20px 10px;
    }

    .main_block_title {
      max-width: 500px;
      font-size: 22px;
      margin: 36px;
      @media only screen and (max-width: 1340px) {
        max-width: 850px;
        margin: 36px 0;
      }
      @media only screen and (max-width: 786px) {
        font-size: 18px;
      }


      h3 {
        font-size: 2em;
        margin: 10px 0;
        color: #929292;

      }

      p {
        margin: 10px 0;
        font-size: 1em;

      }

      span {

      }
    }

    .main_block_images {
      display: flex;
      position: relative;
      flex-direction: column;

      i {
        font-size: 35px;
        position: absolute;
        color: #7c7c7c;
        bottom: 68px;
        left: 65px;
        @media only screen and (max-width: 1340px) {
          bottom: -68px;
        }


        &:before {
          content: '';
          width: 2px;
          height: 147%;
          background: #a2a0a0;
          position: absolute;
          left: 14px;
          top: -69px;
          z-index: 1;
        }

      }

      img {
        width: 300px;
        height: auto;
        object-fit: contain;
        filter: grayscale(1) drop-shadow(2px 4px 6px black);
        @media only screen and (max-width: 440px) {
          width: 100%;
        }

        &:last-child {
          width: 350px;
          filter: grayscale(0) drop-shadow(2px 4px 6px #bfbfbf);
          position: absolute;
          left: 143px;
          @media only screen and (max-width: 740px) {
            width: 300px;
            position: unset;
            margin: 20px;
          }
          @media only screen and (max-width: 440px) {
            width: 100%;
            margin: 20px 0;
          }
        }

      }


    }
  }
}

.menu_coffee {
  width: 100%;
  max-width: 1400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1340px) {
    width: unset;
    margin-top: 63px;
  }

  .text_coffee_background {
    text-align: center;
    font-size: 120px;
    font-weight: bold;
    opacity: 0.05;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    @media only screen and (max-width: 1090px) {
      transform: translateX(-50%) rotate(90deg);
      top: 45px;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 1090px) {
      flex-direction: column;
    }

    li {
      margin: 25px 53px;
      @media only screen and (max-width: 1090px) {
        margin: 25px
      }

      h5 {
        font-size: 23px;
        color: #636363;
        white-space: nowrap;
      }

      span {
        color: #b51e1e;
        font-size: 12px;
        position: relative;

        &:before {
          content: attr(data_menu);
          font-size: 25px;
          position: absolute;
          left: 0;
          top: 0;
          color: #7c7c7c;
          opacity: 0.3;
          z-index: -1;
        }
      }
    }
  }
}

.relaxation_section {
  max-width: 1600px;
  margin: 195px 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 550px) {
    margin: 60px 0;
  }

  .info_relaxation {
    max-width: 450px;
    margin: 60px 40px;
    font-size: 25px;
    @media only screen and (max-width: 1400px) {
      max-width: 1000px;
    }
    @media only screen and (max-width: 950px) {
      margin: 60px 0;
    }

    h2 {
      margin: 10px;
      color: #929292;
    }

    p {
      font-size: 18px;
      margin: 10px;

    }
  }

  .imag_block {
    width: 450px;
    @media only screen and (max-width: 1500px) {
      margin-right: 135px;
    }
    @media only screen and (max-width: 950px) {

      margin-right: unset;
    }
    @media only screen and (max-width: 786px) {
      width: 100%;

    }

    img {
      width: 450px;
      height: auto;
      object-fit: contain;
      margin: -27px 0px;
      filter: drop-shadow(2px 4px 6px black) blur(1px) grayscale(1);
      opacity: 0.9;
      position: relative;
      @media only screen and (max-width: 786px) {
        width: 100%;
        margin: 0;
        opacity: 1;
      }


      &:nth-child(2n) {
        margin: -49px 74px;
        z-index: 2;
        @media only screen and (max-width: 786px) {
          margin: 10px 0;
        }
      }

      &:nth-child(3n) {
        margin: -27px -90px;
        @media only screen and (max-width: 1200px) {
          margin: unset;
        }
      }
    }
  }

  .add_ifo_relaxation {
    max-width: 300px;
    background: #9cb3d8;
    position: relative;
    margin: 0 30px;
    padding: 20px;
    top: 315px;
    z-index: 22;
    @media only screen and (max-width: 1650px) {
      position: absolute;
      top: -156px;
      right: 0;

    }
    @media only screen and (max-width: 950px) {
      max-width: unset;
      position: unset;
      margin: unset;

    }


    p {
      color: white;
      font-size: 18px;
    }
  }
}

.schedule {
  text-align: center;
  font-size: 22px;
  color: #5b5b5b;
  white-space: nowrap;
  h5{
    color: #909090;
  }
  span {
    margin: 5px;
    display: block;
    line-height: 1;
    font-weight: 700;
    color: #747373;
  }
}

